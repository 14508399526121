import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import ImageHeader from "components/ui/base/ImageHeader";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import MobileBox from "components/ui/extended/MobileBox";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.live-chat-plugin-for-wordpress.seo.title")}
        description={t(
          "pages.features.live-chat-plugin-for-wordpress.seo.description"
        )}
        image={{
          relativePath: "meta/wp-live-chat-plugin-for-wordpress-free.jpg",
          alt: "Customerly WP Live Chat Free Plugin Wordpress ",
        }}
      />

      <ImageHeader
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "WP Live Chat Plugin For Wordpress",
            path: "/features/live-chat-plugin-for-wordpress/",
          },
        ]}
        title={t("pages.features.live-chat-plugin-for-wordpress.title")}
        description={t(
          "pages.features.live-chat-plugin-for-wordpress.description"
        )}
        ctaButton={{
          show: true,
          text: t("pages.features.live-chat-plugin-for-wordpress.cta"),
          externalLink: "https://wordpress.org/plugins/customerly/",
          icon: "arrow-right",
        }}
        image={{
          alt: t("pages.features.live-chat-plugin-for-wordpress.title"),
          relativePath:
            "pages/features/live-chat-plugin-for-wordpress/live-chat-plugin-for-wordpress.png",
          maxWidth: 350,
        }}
        circleBackground={{
          show: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat-plugin-for-wordpress.1.title")}
        description={t(
          "pages.features.live-chat-plugin-for-wordpress.1.subtitle"
        )}
        image={{
          relativePath:
            "pages/features/live-chat-plugin-for-wordpress/live-chat-for-wordpress-convert-more-visitors-leads.jpg",
          alt: t("pages.features.live-chat-plugin-for-wordpress.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <HorizontalStoriesMenu
        title={t("pages.features.live-chat-plugin-for-wordpress.2.title")}
        paragraph={t(
          "pages.features.live-chat-plugin-for-wordpress.2.subtitle"
        )}
        storiesPosition={"right"}
        elements={[
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.2.steps.3.title"
            ),
            story: {
              relativePath:
                "pages/features/help-center/help-center-software.mov",
            },
          },
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.2.steps.1.title"
            ),
            story: {
              relativePath:
                "components/customer-service-stories/in-chat-help-center.mov",
            },
          },
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.2.steps.2.title"
            ),
            story: {
              relativePath:
                "pages/features/help-center/live-chat-help-center-autoreply.mov",
            },
          },
        ]}
        cta={{
          text: t("pages.features.live-chat-plugin-for-wordpress.2.steps.cta"),
          link: "/features/help-center/",
        }}
      />

      <MobileBox />

      <ImageWithDescription
        title={t("pages.features.live-chat-plugin-for-wordpress.3.title")}
        description={t(
          "pages.features.live-chat-plugin-for-wordpress.3.subtitle"
        )}
        image={{
          relativePath:
            "pages/features/live-chat-plugin-for-wordpress/live-chat-for-wordpress-customer.jpg",
          alt: t("pages.features.live-chat-plugin-for-wordpress.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.live-chat-plugin-for-wordpress.4.title")}
        description={t(
          "pages.features.live-chat-plugin-for-wordpress.4.subtitle"
        )}
        image={{
          relativePath:
            "pages/features/live-chat-plugin-for-wordpress/live-chat-for-wordpress-user-tracking.jpg",
          alt: t("pages.features.live-chat-plugin-for-wordpress.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.features.live-chat-plugin-for-wordpress.others.title")}
        centered={false}
        sections={[
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.others.newsletter.title"
            ),
            description: t(
              "pages.features.live-chat-plugin-for-wordpress.others.newsletter.description"
            ),
            image: {
              alt: "Newsletter",
              relativePath: "icons/newsletter.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat-plugin-for-wordpress.others.newsletter.cta"
                ),
                path: "/features/email-marketing/",
              },
            ],
          },
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.others.funnels.title"
            ),
            description: t(
              "pages.features.live-chat-plugin-for-wordpress.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat-plugin-for-wordpress.others.funnels.cta"
                ),
                path: "/features/marketing-funnel/",
              },
            ],
          },
          {
            title: t(
              "pages.features.live-chat-plugin-for-wordpress.others.videoLiveChat.title"
            ),
            description: t(
              "pages.features.live-chat-plugin-for-wordpress.others.videoLiveChat.description"
            ),
            image: {
              alt: "Video Live Chat",
              relativePath: "icons/video-live-chat.png",
            },
            links: [
              {
                text: t(
                  "pages.features.live-chat-plugin-for-wordpress.others.videoLiveChat.cta"
                ),
                path: "/features/video-live-chat/",
              },
            ],
          },
        ]}
      />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
